import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";

import Add from "@mui/icons-material/Add";

export const CreateBankTransfer = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const {
    mutate: createBankTransfer,
    isLoading,
    isSuccess,
  } = useMutation((data) => dataProvider.createBankTransfer(data), {
    onSuccess: () => {
      refresh();
      notify("Bank transfer created", { type: "success" });
    },
    onError: (err) => {
      notify(err.error, { type: "warning" });
    },
  });

  return (
    <>
      <CreateBankTransferDialog
        handleConfirm={(data) => createBankTransfer(data)}
        isSuccess={isSuccess}
        isLoading={isLoading}
      />
    </>
  );
};

const CreateBankTransferDialog = (props) => {
  const { handleConfirm, isSuccess, isLoading } = props;
  const [dialogStatus, setDialogStatus] = useState(false);
  const [amount, setAmount] = useState("");
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [customerQuery, setCustomerQuery] = useState("");

  const dataProvider = useDataProvider();

  useEffect(() => {
    fetchWallets();
  }, [selectedCustomerId]);

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [customerQuery]);

  const fetchWallets = async () => {
    const { data } = await dataProvider.getList("wallets", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        customer_id: selectedCustomerId?.id,
      },
    });

    setWallets(data);
  };

  const fetchBankAccounts = async () => {
    const { data } = await dataProvider.getList("bank-accounts", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {},
    });
    setBankAccounts(data);
  };

  const fetchCustomers = async () => {
    const { data } = await dataProvider.getList("customers", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        q: customerQuery,
      },
    });

    setCustomers(data);
  };

  const handleDialogStatus = () => {
    resetForm();
    setDialogStatus(!dialogStatus);
  };

  const handleSetAmount = (value) => {
    if (!value) {
      setAmount("");
      return;
    }

    setAmount(+value);
  };

  const resetForm = () => {
    setAmount("");
    setSelectedWallet(null);
    setSelectedBankAccountId(null);
    setSelectedCustomerId(null);
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogStatus();
    }
  }, [isSuccess]);

  const disabledCreate = useMemo(
    () => isLoading || !amount || !selectedWallet || !selectedBankAccountId,
    [isLoading, amount, selectedWallet, selectedBankAccountId]
  );

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div>
      <Button
        onClick={() => handleDialogStatus(true)}
        label="Create a Bank Transfer"
      >
        <Add />
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={dialogStatus}
        onClose={handleDialogStatus}
      >
        <DialogTitle>Create a Bank Transfer</DialogTitle>
        <DialogContent sx={!fullScreen && { width: 400 }}>
          <Autocomplete
            options={customers}
            getOptionLabel={({ id, title }) => `${id} - ${title}`}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoFocus
                  label="Customer"
                  variant="standard"
                  fullWidth
                  value={customerQuery}
                  onChange={(newValue) =>
                    setCustomerQuery(newValue.target.value)
                  }
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedCustomerId(newValue);
              setSelectedWallet(null);
            }}
          />
          <Autocomplete
            disabled={!selectedCustomerId}
            options={wallets}
            value={selectedWallet}
            getOptionLabel={({ id, name, currency_code }) =>
              `${id} - ${name} - ${currency_code}`
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Wallet"
                  variant="standard"
                  fullWidth
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedWallet(newValue);
            }}
          />
          <Autocomplete
            options={bankAccounts}
            getOptionLabel={({
              id,
              name,
              currency_code,
              payment_method,
              meta,
            }) => {
              if (payment_method === "crypto")
                return `${id} - ${name} - ${currency_code} - ${meta.protocol}`;
              return `${id} - ${name} - ${currency_code}`;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Bank Account"
                  variant="standard"
                  fullWidth
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedBankAccountId(newValue);
            }}
          />
          <TextField
            id="amount"
            label="Amount"
            type="number"
            variant="standard"
            fullWidth
            value={amount}
            onChange={(newValue) => handleSetAmount(newValue.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="Cancel"
            onClick={handleDialogStatus}
            size="small"
            sx={
              fullScreen && {
                fontSize: "1rem",
                border: "1px solid #1976d2",
                borderRadius: "5px",
              }
            }
          >
            {fullScreen ? "Cancel" : ""}
          </Button>
          <Button
            label="Create"
            onClick={() =>
              handleConfirm({
                amount,
                wallet_id: +selectedWallet?.id,
                bank_account_id: +selectedBankAccountId?.id,
              })
            }
            disabled={disabledCreate}
            sx={
              fullScreen && {
                fontSize: "1rem",
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
              }
            }
          >
            <>
              {isLoading ? (
                <CircularProgress
                  size={14}
                  sx={{
                    color: "#1976d2",
                  }}
                />
              ) : fullScreen ? (
                "Create"
              ) : (
                ""
              )}
            </>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
