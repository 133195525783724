const auth = {
  checkAuth: () => {
    if (localStorage.getItem("token")) {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("RaStore")) {
          localStorage.removeItem(key);
        }
      });

      return Promise.resolve();
    }
    return Promise.reject();
  },
  checkError: (error) => {
    if (error?.status === 401 || error?.code === "UNAUTHORIZED") {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    if (error?.status === 403 || error?.code === "FORBIDDEN") {
      return Promise.reject({ redirectTo: "/no-access", logoutUser: false });
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
};

export default auth;
