import {
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  TextInput,
} from "react-admin";

import { Box, Stack } from "@mui/material";

const filters = [
  <TextInput label="Transaction Id" source="id" />,
  <TextInput label="Wallet Id" source="wallet_id" />,
  <SelectInput
    source="type"
    choices={[
      { id: "credit", name: "Credit" },
      { id: "debit", name: "Debit" },
    ]}
  />,
  <SelectInput
    source="kind"
    choices={[
      { id: "deposit", name: "Deposit" },
      { id: "transfer2adaccount", name: "Transfer to ad account" },
      { id: "withdraw4adaccount", name: "Withdraw from ad account" },
    ]}
  />,
  <TextInput label="Ad Account Id" source="ad_account_id" />,
];

const WalletTransactionsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export function WalletTransactions() {
  return (
    <List
      actions={<WalletTransactionsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <TextField source="kind" />
        <TextField source="type" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="wallet_id"
          reference="wallets"
          link="show"
          label="Wallet ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="ad_account_id" />
        <TextField source="ad_account_name" />
        <TextField source="ad_account_alias_name" />
        <TextField source="currency_code" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
}

export function WalletTransaction() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="kind" />
        <TextField source="type" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="wallet_id"
          reference="wallets"
          link="show"
          label="Wallet ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="ad_account_id" />
        <TextField source="ad_account_name" />
        <TextField source="ad_account_alias_name" />
        <TextField source="currency_code" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
}
