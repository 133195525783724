import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SelectInput,
  TextInput,
  ReferenceInput,
  NumberField,
  Pagination,
  AutocompleteInput,
  BulkExportButton,
  ReferenceManyField,
} from "react-admin";
import { PriceField } from "../customFields";

const filters = [
  <TextInput label="ID" source="id" />,
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
  <SelectInput
    source="payment_status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "in_progress", name: "In Progress" },
      { id: "unpaid", name: "Unpaid" },
      { id: "paid", name: "Paid" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <SelectInput
    source="type"
    choices={[
      { id: "commission", name: "Commission" },
      { id: "fee", name: "Fee" },
    ]}
  />,
];

const BulkActionButtons = () => (
  <>
    <BulkExportButton />
  </>
);

export const PaymentList = () => (
  <List
    filters={filters}
    sort={{ field: "created_at", order: "DESC" }}
    pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
  >
    <Datagrid
      rowClick="show"
      size="medium"
      bulkActionButtons={<BulkActionButtons />}
    >
      <TextField source="id" />
      <ReferenceField
        source="customer_id"
        reference="customers"
        link="show"
        label="Customer Name"
      >
        <TextField source="title" />
      </ReferenceField>
      <NumberField
        source="amount"
        options={{
          style: "currency",
          currency: "USD",
          locales: "tr-TR",
        }}
      />
      <TextField source="status" />
      <DateField source="created_at" showTime locales="tr-TR" />
      <DateField source="updated_at" showTime locales="tr-TR" />
    </Datagrid>
  </List>
);

export const PaymentShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        source="customer_id"
        reference="customers"
        link="show"
        label="Customer Name"
      >
        <TextField source="title" />
      </ReferenceField>
      <PriceField source="amount" />
      <TextField source="status" />
      <DateField source="created_at" showTime locales="tr-TR" />
      <DateField source="updated_at" showTime locales="tr-TR" />
    </SimpleShowLayout>
  </Show>
);

export function PaymentTab({ reference, target, pagination, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      pagination={pagination}
      addLabel={addLabel}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <PriceField source="amount" />

        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="status" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
