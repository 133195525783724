/* eslint-disable no-undef */
import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = `${process.env.REACT_APP_API_URL}/admin`;
const publicAPI = `${process.env.REACT_APP_API_URL}`;

const httpClient = (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: url.includes("admin") ? localStorage.getItem("token") : null, // token for admin api
  };

  return fetchUtils.fetchJson(url, options).catch(({ body: err }) => {
    // react-admin shows "server communication err" msg on any api error
    // we want to show more human readable msg on 403 (user don't have perm)
    if (err?.status == 403) {
      throw new Error("you don't have permission to perform this action");
    }
    throw err;
  });
};

export default {
  async getList(resource, params) {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      offset: (page - 1) * perPage,
      limit: perPage,
      sort_by: field,
      sort_order: order,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    // there is no id in some of the data, so we add it here
    return await httpClient(url).then(({ json }) => ({
      data: json.data.map((x, index) => ({ ...x, id: x.id || index })),
      total: json.total,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      return Object.keys(json).length === 1
        ? json.data.length === 1
          ? { data: json[Object.keys(json)[0]][0] }
          : { data: json[Object.keys(json)[0]] }
        : { data: json };
    }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return await httpClient(url).then(({ json }) => json);
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (resource === "invoices") {
      const formData = new FormData();
      Object.keys(params.data).forEach((key) => {
        if (key === "file") {
          formData.append("invoice_file", params.data.file.rawFile);
        } else {
          formData.append(key, params.data[key]);
        }
      });
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(() => ({
        data: { ...params.data, id: params.data.invoice_number },
      }));
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  getCommissionCategories: () => {
    const url = `${apiUrl}/commission-categories`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  approveSubscription: (subscriptionID) => {
    return httpClient(`${apiUrl}/subscriptions/${subscriptionID}/approve`, {
      method: "POST",
    }).then(({ json }) => ({ data: json }));
  },

  rejectSubscription: (subscriptionID) => {
    return httpClient(`${apiUrl}/subscriptions/${subscriptionID}/reject`, {
      method: "POST",
    }).then(({ json }) => ({ data: json }));
  },

  approveAdAccount: (payload) => {
    const body = {
      commission_category_id: +payload.categoryId,
      currency: payload.currency,
      platform_settings_id: +payload.bmID,
      timezone: payload.timezone,
      tiktok_industry: +payload.industry,
      tiktok_country_code: payload.countryCode,
      credit_account_id: +payload.creditAccountId,
    };

    return httpClient(`${apiUrl}/ad-accounts/${payload.adAccountId}/approve`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then(({ json }) => ({ data: json }));
  },

  rejectAdAccount: ({ adAccountId, data }) => {
    return httpClient(`${apiUrl}/ad-accounts/${adAccountId}/reject`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  approveBankTransfer: (bankTransferId, amount, bankAccountId) => {
    return httpClient(`${apiUrl}/bank-transfers/${bankTransferId}/approve`, {
      method: "POST",
      body: JSON.stringify({
        amount: +amount,
        bank_account_id: +bankAccountId,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  rejectBankTransfer: (bankTransferId) => {
    return httpClient(`${apiUrl}/bank-transfers/${bankTransferId}/reject`, {
      method: "POST",
      body: JSON.stringify({ reason: "Not implemented yet" }),
    }).then(({ json }) => ({ data: json }));
  },

  attachAdaccounts: (quantity, adAccountID, platformId) => {
    return httpClient(
      `${apiUrl}/subscriptions/${adAccountID}/attach-adaccounts`,
      {
        method: "POST",
        body: JSON.stringify({ quantity, platform_id: platformId }),
      }
    ).then(({ json }) => ({ data: json }));
  },

  createBankTransfer: (data) => {
    return httpClient(`${apiUrl}/bank-transfers`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(() => ({
      data: { success: true },
    }));
  },

  sendKYCVerification: (customer_id) => {
    return httpClient(`${apiUrl}/kyc-forms`, {
      method: "POST",
      body: JSON.stringify({ customer_id }),
    }).then(({ json }) => ({ data: json }));
  },
  getBmIDs: (platformId) => {
    const query = {
      filter: JSON.stringify({ platform_id: platformId }),
    };
    return httpClient(`${apiUrl}/platform-settings?${stringify(query)}`).then(
      ({ json }) => json
    );
  },
  getCountries: async () => {
    const { json } = await httpClient(`${publicAPI}/countries`);
    return json;
  },

  getPlatformCreditAccounts: async () => {
    const { json } = await httpClient(`${apiUrl}/platform-credit-accounts`);

    return json;
  },

  getTimezones: async () => {
    const { json } = await httpClient(`${publicAPI}/timezones`);
    return json;
  },

  getCustomerData: (id) => {
    return httpClient(`${apiUrl}/customers/${id}`).then(({ json }) => ({
      data: json,
    }));
  },

  getWalletById: (id) => {
    return httpClient(`${apiUrl}/wallets/${id}`).then(({ json }) => ({
      data: json,
    }));
  },

  createComment: ({ data, recordId }) => {
    return httpClient(`${apiUrl}/customers/${recordId}/comments`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(() => ({
      data: { success: true },
    }));
  },

  exportWalletHistory: (id) => {
    return httpClient(
      `${apiUrl}/customers/financial-histories/export?filter={"customer_id":"${id}"}`
    ).then((json) => {
      const url = window.URL.createObjectURL(new Blob([json.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Asset Manager.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      return {
        data: json.body,
      };
    });
  },

  approveDemandById: (id, amount = null) => {
    const body =
      amount !== null ? JSON.stringify({ withdraw_amount: amount }) : null;

    return httpClient(`${apiUrl}/demands/${id}/approve`, {
      method: "POST",
      body,
    }).then(({ json }) => ({ data: json }));
  },

  rejectDemandById: (id) => {
    return httpClient(`${apiUrl}/demands/${id}/reject`, {
      method: "POST",
    }).then(({ json }) => ({ data: json }));
  },

  createAnAdAccount: ({ customer_id, wallet_id, quantity, platform_id }) => {
    return httpClient(`${apiUrl}/customers/${customer_id}/ad-accounts`, {
      method: "POST",
      body: JSON.stringify({
        wallet_id,
        quantity,
        platform_id,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  walletRefund: (payload) => {
    return httpClient(`${apiUrl}/payments/refund`, {
      method: "POST",
      body: JSON.stringify(payload),
    }).then(({ json }) => ({ data: json }));
  },

  syncBalance: (id) => {
    return httpClient(`${apiUrl}/ad-accounts/${id}/sync`, {
      method: "PUT",
    }).then(({ json }) => ({ data: json }));
  },

  createWithdrawDemandByBannedAdAccount: (id) => {
    return httpClient(`${apiUrl}/ad-accounts/${id}/create-withdraw-demand`, {
      method: "POST",
    }).then(({ json }) => ({ data: json }));
  },

  approveLeadById: (id, { comment }) => {
    return httpClient(`${apiUrl}/leads/${id}/approve`, {
      method: "PUT",
      body: JSON.stringify({ comment }),
    }).then(({ json }) => ({ data: json }));
  },

  approveLeadByIdWithPartner: (id, { comment, customer_id }) => {
    return httpClient(`${apiUrl}/leads/${id}/send-to-partner`, {
      method: "PUT",
      body: JSON.stringify({ comment, customer_id }),
    }).then(({ json }) => ({ data: json }));
  },

  rejectLeadById: (id, { comment }) => {
    return httpClient(`${apiUrl}/leads/${id}/reject`, {
      method: "PUT",
      body: JSON.stringify({ comment }),
    }).then(({ json }) => ({ data: json }));
  },

  fetchFinancialSummaryByCustomerId: async (id) => {
    return httpClient(`${apiUrl}/customers/${id}/financial-summary`).then(
      ({ json }) => json
    );
  },
};
