import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Tab,
  TabbedShowLayout,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";

import { Box, Stack } from "@mui/material";
import { RefundButton } from "./Refund";
import { useParams } from "react-router-dom";

const filters = [
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput source="name" label="Name" reference="wallets" allowEmpty>
    <AutocompleteInput optionText="name" optionValue="name" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput source="code" label="Code" reference="wallets" allowEmpty>
    <AutocompleteInput optionText="code" optionValue="code" debounce={500} />
  </ReferenceInput>,
  <SelectInput
    source="currency_code"
    choices={[
      { id: "eur", name: "EUR" },
      { id: "usd", name: "USD" },
      { id: "try", name: "TRY" },
    ]}
  />,
];

const WalletsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export const WalletsList = () => {
  return (
    <List
      actions={<WalletsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="balance" />
        <TextField source="currency_code" />
        <TextField source="card_id" />
        <TextField source="auto_payment" />
        <TextField source="auto_payment_threshold" />
        <TextField source="auto_payment_amount" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
        <DateField source="deleted_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

const WalletActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <RefundButton id={record?.id} />
    </TopToolbar>
  );
};

const TransactionsHistoryListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

export const Wallet = () => {
  const { id } = useParams();

  return (
    <Show actions={<WalletActions />}>
      <TabbedShowLayout>
        <Tab label="Wallet Detail">
          <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField
              source="customer_id"
              reference="customers"
              link="show"
              label="Customer Name"
            >
              <TextField source="title" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="balance" />
            <TextField source="code" />
            <TextField source="card_id" />
            <TextField source="auto_payment_threshold" />
            <TextField source="auto_payment" />
            <TextField source="currency_code" />
            <TextField source="auto_payment_amount" />
            <DateField source="created_at" showTime locales="tr-TR" />
            <DateField source="updated_at" showTime locales="tr-TR" />
            <DateField source="deleted_at" showTime locales="tr-TR" />
          </SimpleShowLayout>
        </Tab>

        <Tab label="Transactions History" path="transactions">
          <ReferenceManyField
            reference="payments/wallet-transactions"
            target="wallet_id"
            addLabel={false}
          >
            <List
              actions={<TransactionsHistoryListActions />}
              filter={{ wallet_id: id }}
            >
              <Datagrid size="medium">
                <TextField source="id" />
                <TextField source="type" />
                <TextField source="kind" />
                <TextField source="customer_id" />
                <TextField source="user_id" />
                <TextField source="user_name" />
                <TextField source="user_email" />
                <TextField source="wallet_id" />
                <TextField source="ad_account_id" />
                <TextField source="ad_account_name" />
                <TextField source="ad_account_alias_name" />
                <TextField source="amount" />
                <TextField source="currency_code" />
                <TextField source="commission_amount" />
                <TextField source="commission_rate" />
                <TextField source="card_fee" />
                <TextField source="card_fee_rate" />
                <DateField source="created_at" showTime locales="tr-TR" />
                <DateField source="updated_at" showTime locales="tr-TR" />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export function WalletTab({ reference, target, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="balance" />
        <TextField source="currency_code" />
        <TextField source="card_id" />
        <TextField source="auto_payment" />
        <TextField source="auto_payment_threshold" />
        <TextField source="auto_payment_amount" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
        <DateField source="deleted_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
