import { useState } from "react";
import {
  useNotify,
  useRefresh,
  useDataProvider,
  Button,
  Confirm,
} from "react-admin";

import { useMutation } from "react-query";

import ClearIcon from "@mui/icons-material/Clear";

export const RejectButton = ({ bankTransferId, wallet }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const approve = useMutation(
    ["rejectBankTransfer", bankTransferId],
    () => dataProvider.rejectBankTransfer(bankTransferId),
    {
      onSuccess: () => {
        refresh();
        notify("Bank transfer rejected", { type: "success" });
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: wallet.balance === 0 ? 'bank_transfer_rejected_first' : 'bank_transfer_rejected',
          payment_method: 'Havale', // Havale, Kredi Kartı, Paypal
          currency: wallet.currency_code, // USD, EUR, TRY
          credit_balance: wallet.balance, // Mevcut cüzdan bakiyesi
        })
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    approve.mutate({ bankTransferId });
    setOpen(false);
  };

  return (
    <>
      <Button
        label="Reject"
        startIcon={<ClearIcon />}
        onClick={handleClick}
        disabled={approve.isLoading}
      />
      <Confirm
        title="Are you sure?"
        content="This bank transfer will be rejected."
        isOpen={open}
        onConfirm={handleConfirm}
        onClose={handleClose}
        isLoading={approve.isLoading}
      ></Confirm>
    </>
  );
};
