import * as React from "react";
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  WithRecord,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Stack, Box } from "@mui/material";
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  TextField as MuiTextField,
} from "@mui/material";

// Icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation } from "react-query";
import { useState } from "react";

const Approve = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [isWithdrawVisible, setIsWithdrawVisible] = useState(false);

  const { mutate, isLoading, isSuccess } = useMutation(
    (amount = null) => dataProvider.approveDemandById(record.id, amount),
    {
      onSuccess: () => {
        setIsWithdrawVisible(false);
        refresh();

        if (
          record?.demand_name === "ad-account-withdraw" &&
          ["in_progress", "failed"].includes(record?.status)
        ) {
          return notify("Withdraw request is in progress", { type: "primary" });
        }

        notify("Approved Demand", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  return (
    <>
      {record?.demand_name === "ad-account-withdraw" &&
      ["in_progress", "failed"].includes(record?.status) ? (
        <Button
          color="primary"
          label="Approve"
          startIcon={<CheckIcon />}
          onClick={() => setIsWithdrawVisible(true)}
        />
      ) : (
        <Button
          color="primary"
          label="Approve"
          startIcon={<CheckIcon />}
          disabled={isLoading || isSuccess || record?.status !== "in_progress"}
          onClick={() => mutate()}
        />
      )}

      {isWithdrawVisible && (
        <WithdrawAmountModal
          amount={record?.payload?.withdraw_amount}
          isWithdrawVisible={isWithdrawVisible}
          close={() => setIsWithdrawVisible(false)}
          submit={mutate}
        />
      )}
    </>
  );
};

const WithdrawAmountModal = ({ isWithdrawVisible, amount, submit, close }) => {
  const [withdrawAmount, setWithdrawAmount] = useState(amount);

  return (
    <Dialog open={isWithdrawVisible} onClose={close}>
      <DialogTitle>Withdraw Amount</DialogTitle>

      <DialogContent sx={{ width: 400 }}>
        <MuiTextField
          label="Amount"
          fullWidth
          className=""
          value={withdrawAmount}
          onChange={(newValue) => setWithdrawAmount(newValue.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button label="Cancel" onClick={close} />
        <Button label="Submit" onClick={() => submit(withdrawAmount)} />
      </DialogActions>
    </Dialog>
  );
};

const Reject = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { mutate, isLoading, isSuccess } = useMutation(
    () => dataProvider["rejectDemandById"](record.id),
    {
      onSuccess: () => {
        refresh();
        notify("Rejected Demand", { type: "info" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  return (
    <Button
      label="Reject"
      startIcon={<ClearIcon />}
      onClick={mutate}
      disabled={isLoading || isSuccess || record?.status !== "in_progress"}
    />
  );
};

const DemandActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Approve record={record} />
      <Reject record={record} />
    </TopToolbar>
  );
};

const filters = [
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <SelectInput
    source="status"
    choices={[
      { id: "in_progress", name: "In_Progress" },
      { id: "completed", name: "Completed" },
      { id: "rejected", name: "Rejected" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <SelectInput
    source="demand_name"
    choices={[
      { id: "ad-account-withdraw", name: "Ad Account Withdraw" },
      { id: "ad-account-delete", name: "Ad Account Delete" },
    ]}
  />,
  <ReferenceInput source="id" label="Model" reference="demands" allowEmpty>
    <AutocompleteInput optionText="object_name" debounce={500} label="Model" />
  </ReferenceInput>,
  <DateInput source="created_at_gte" label="Created At Gte (Start Date)" />,
  <DateInput source="created_at_lte" label="Created At Lte (End Date)" />,
];

const DemandsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export const DemandsList = () => {
  return (
    <List
      actions={<DemandsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer"
        >
          <TextField source="title" />
        </ReferenceField>
        <WithRecord
          label="Model"
          render={(record) => {
            return (
              <ReferenceField
                source="object_id"
                reference={record?.model.replaceAll("_", "-")}
                link="show"
                sx={{ fontSize: "14px" }}
              >
                <span title={`${record.object_id} - ${record.object_name}`}>
                  {record.object_name}
                </span>
              </ReferenceField>
            );
          }}
        />
        <WithRecord
          label="Actor"
          render={(record) => {
            const actor = record?.approved_by || record?.rejected_by;
            return <span>{actor}</span>;
          }}
        />
        <TextField source="demand_name" label="Demand Name" />
        <TextField source="status" />
        <DateField source="created_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

export const Demand = () => {
  return (
    <Show actions={<DemandActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="user_id" reference="users" link="show">
          <TextField source="full_name" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Title"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Id"
        >
          <TextField source="id" />
        </ReferenceField>
        <WithRecord
          label="Object Id"
          render={(record) => {
            return (
              <ReferenceField
                source="object_id"
                reference={record?.model.replaceAll("_", "-")}
                link="show"
                sx={{ fontSize: "14px" }}
              >
                <span>{record.object_id}</span>
              </ReferenceField>
            );
          }}
        />
        <WithRecord
          label="Object Name"
          render={(record) => {
            return (
              <ReferenceField
                source="object_id"
                reference={record?.model.replaceAll("_", "-")}
                link="show"
                sx={{ fontSize: "14px" }}
              >
                <span>{record.object_name}</span>
              </ReferenceField>
            );
          }}
        />
        <TextField source="payload.withdraw_amount" label="Withdraw Amount" />
        <ReferenceField
          source="object_id"
          reference="ad-accounts"
          label="Currency Code"
          link={false}
        >
          <TextField source="currency_code" />
        </ReferenceField>
        <TextField source="approved_by" />
        <TextField source="rejected_by" />
        <TextField source="status" />
        <TextField source="error_message" />
        <TextField source="url" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />

        <WithdrawAmountModal />
      </SimpleShowLayout>
    </Show>
  );
};
