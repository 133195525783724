import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// Icons
import ClearIcon from "@mui/icons-material/Clear";

export const RejectButton = ({ adAccountId }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const {
    mutate: rejectAdAccount,
    isLoading,
    isSuccess,
  } = useMutation(
    (data) => dataProvider.rejectAdAccount({ adAccountId, data }),
    {
      onSuccess: () => {
        refresh();
        notify("Rejected Ad Account", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );
  return (
    <>
      <RejectAdAccountDialog
        handleConfirm={(data) => rejectAdAccount(data)}
        isSuccess={isSuccess}
        isLoading={isLoading}
      />
    </>
  );
};

const RejectAdAccountDialog = (props) => {
  const { handleConfirm, isSuccess, isLoading } = props;
  const [dialogStatus, setDialogStatus] = useState(false);
  const [other, setOther] = useState("");
  const [reason, setReason] = useState("");
  const reasons = [
    { key: "scam", name: "Scam" },
    { key: "harmful content", name: "Harmful content" },
    { key: "dangerous content", name: "Dangerous content" },
    { key: "banned content", name: "Banned content" },
    { key: "incompatible policy", name: "Incompatible policy" },
    { key: "missing additional data", name: "Missing additional data" },
    { key: "wrong additional data", name: "Wrong additional data" },
    { key: "other", name: "Other" },
  ];

  const handleDialogStatus = () => {
    resetForm();
    setDialogStatus(!dialogStatus);
  };

  const resetForm = () => {
    setOther("");
    setReason("");
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialogStatus();
    }
  }, [isSuccess]);

  return (
    <div>
      <Button
        label="Reject"
        startIcon={<ClearIcon />}
        onClick={() => handleDialogStatus(true)}
        // disabled={approve.isLoading}
      />
      <Dialog open={dialogStatus} onClose={handleDialogStatus}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <p>This ad account will be rejected.</p>
          <FormControl fullWidth>
            <InputLabel id="reason-label">Reason</InputLabel>
            <Select
              labelId="reason-label"
              id="reason"
              value={reason}
              label="Reason"
              onChange={(e) => setReason(e.target.value)}
            >
              {reasons.map((category) => {
                return (
                  <MenuItem key={category.name} value={category.key}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {reason === "other" && (
            <FormControl fullWidth>
              <TextField
                id="other"
                label="Other"
                type="text"
                variant="standard"
                fullWidth
                multiline
                value={other}
                onChange={(newValue) => setOther(newValue.target.value)}
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleDialogStatus} />
          <Button
            disabled={!reason || (reason === "other" && !other)}
            label="Reject"
            onClick={() =>
              handleConfirm({ reason: reason === "other" ? other : reason })
            }
          >
            <>
              {isLoading && (
                <CircularProgress
                  size={14}
                  sx={{
                    color: "#1976d2",
                  }}
                />
              )}
            </>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
