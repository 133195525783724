import React from "react";
import { useMutation } from "react-query";
import { Button, useDataProvider, useNotify, useRefresh } from "react-admin";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

export function Approve({ record }) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClose = () => setOpen(false);

  const { mutate, isLoading, isSuccess } = useMutation(
    ({ comment }) => dataProvider.approveLeadById(record.id, { comment }),
    {
      onSuccess: () => {
        refresh();
        notify("Approved Lead", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  function handleApproveBtn() {
    if (!record.email_verified_at) {
      notify("E-Mail Verification Required", { type: "warning" });

      return;
    }

    setOpen(true);
  }

  return (
    <>
      <Button
        disabled={
          ["approved"].includes(record?.status) || isLoading || isSuccess
        }
        color="primary"
        label="Approve"
        startIcon={<CheckIcon />}
        onClick={() => handleApproveBtn()}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Approve Lead</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Comment"
            type="text"
            variant="standard"
            fullWidth
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!comment || isLoading || isSuccess}
            color="primary"
            onClick={() => {
              mutate({ comment });
              setOpen(false);
            }}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
