import * as React from "react";
import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextInput,
  BooleanField,
  ReferenceInput,
  AutocompleteInput,
  BulkExportButton,
  ReferenceManyField,
} from "react-admin";

const filters = [
  <TextInput label="ID" source="id" />,
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
  <TextInput label="Email" source="email" />,
];

const BulkActionButtons = () => (
  <>
    <BulkExportButton />
  </>
);

export const UserList = () => (
  <List
    filters={filters}
    sort={{ field: "created_at", order: "DESC" }}
    pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
  >
    <Datagrid
      rowClick="show"
      size="medium"
      bulkActionButtons={<BulkActionButtons />}
    >
      <TextField source="id" />
      <ReferenceField
        source="customer_id"
        reference="customers"
        link="show"
        label="Customer Name"
      >
        <TextField source="title" />
      </ReferenceField>
      <TextField source="email" sortable={false} />
      <DateField source="created_at" showTime locales="tr-TR" />
      <DateField source="updated_at" showTime locales="tr-TR" />
    </Datagrid>
  </List>
);

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        source="customer_id"
        reference="customers"
        link="show"
        label="Customer Name"
      >
        <TextField source="title" />
      </ReferenceField>
      <TextField source="full_name" />
      <TextField source="email" />
      <BooleanField source="is_email_verified" />
      <TextField source="phone_number" />
      <BooleanField source="is_phone_verified" />
      <DateField source="last_login_date" showTime locales="tr-TR" />
      <DateField source="created_at" showTime locales="tr-TR" />
      <DateField source="updated_at" showTime locales="tr-TR" />
    </SimpleShowLayout>
  </Show>
);

export function UserTab({ reference, target, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name_surname" />
        <TextField source="email" />
        <BooleanField source="email_verify" />
        <TextField source="phone_number" />
        <BooleanField source="phone_verify" />
        <DateField source="last_login_date" showTime locales="tr-TR" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
