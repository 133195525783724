import {
  List,
  Datagrid,
  TextField,
  DateField,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceField,
  SelectInput,
  FilterForm,
  TopToolbar,
  FilterButton,
  ExportButton,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  TextInput,
} from "react-admin";

import { Stack, Box } from "@mui/material";

const filters = [
  <TextInput label="Title" source="title" />,
  <SelectInput
    source="account_type"
    choices={[
      { id: "individual", name: "Individual" },
      { id: "corporate", name: "Corporate" },
    ]}
  />,
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
];

const BillingAccountsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export const BillingAccountList = () => {
  return (
    <List
      actions={<BillingAccountsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="account_type" />
        <TextField source="title" />
        <TextField source="tax_number" />
        <TextField source="tax_office" />
        <TextField source="email" />
        <TextField source="phone_number" />
        <TextField source="address" />
        <TextField source="country_code" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

export const BillingAccountShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="account_type" />
        <TextField source="title" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="tax_number" />
        <TextField source="tax_office" />
        <TextField source="email" />
        <TextField source="phone_number" />
        <TextField source="address" />
        <TextField source="country_code" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
};

export function BillingAccountTab({ reference, target }) {
  return (
    <ReferenceManyField reference={reference} target={target}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="tax_number" />
        <TextField source="tax_office" />
        <TextField source="email" />
        <TextField source="phone_number" />
        <TextField source="address" />
        <TextField source="country_code" />
        <TextField source="status" />
        <TextField source="created_at" />
        <TextField source="updated_at" />
      </Datagrid>
    </ReferenceManyField>
  );
}
