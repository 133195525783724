import { useEffect, useState } from "react";

import { Button, useDataProvider, useNotify, useRefresh } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";

// Icons
import Add from "@mui/icons-material/Add";
import { useMutation } from "react-query";

export const CreateAdAccount = () => {
  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customers, setCustomers] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [quantity, setQuantity] = useState("");
  const [customerQuery, setCustomerQuery] = useState("");
  const [platformId, setPlatformId] = useState("");

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    fetchCustomers();
    setSelectedWallet("");
  }, [customerQuery]);

  useEffect(() => {
    fetchWallets();
  }, [selectedCustomer]);

  const fetchCustomers = async () => {
    const { data } = await dataProvider.getList("customers", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        q: customerQuery,
      },
    });

    setCustomers(data);
  };

  const fetchWallets = async () => {
    const { data } = await dataProvider.getList("wallets", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        customer_id: selectedCustomer?.id,
      },
    });

    setWallets(data);
  };

  const { mutate, isLoading, isSuccess } = useMutation(
    (data) => dataProvider.createAnAdAccount(data),
    {
      onSuccess: () => {
        refresh();
        notify("Ad Account created", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      resetForm();
    }
  }, [isSuccess]);

  const resetForm = () => {
    setSelectedCustomer("");
    setSelectedWallet("");
    setQuantity("");
    setPlatformId("");
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        label="Create Ad Account"
      >
        <Add />
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Create an Ad Account</DialogTitle>

        <DialogContent sx={{ width: 400 }}>
          <Autocomplete
            options={customers}
            getOptionLabel={({ id, title }) => `${id} - ${title}`}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoFocus
                  label="Customer"
                  variant="standard"
                  fullWidth
                  value={customerQuery}
                  onChange={(newValue) => {
                    setCustomerQuery(newValue.target.value);
                    setSelectedWallet("");
                  }}
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedCustomer(newValue);
              setSelectedWallet("");
            }}
          />
          <Autocomplete
            disabled={!selectedCustomer?.id}
            options={wallets}
            value={selectedWallet}
            getOptionLabel={(option) => {
              if (option) {
                return `${option.id} - ${option.name} - ${option.currency_code}`;
              }
              return "";
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Wallet"
                  variant="standard"
                  fullWidth
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedWallet(newValue);
            }}
          />
          <Autocomplete
            options={[
              {
                label: "Facebook",
                value: 1,
              },
              {
                label: "Google",
                value: 4,
              },
              {
                label: "Tiktok",
                value: 3,
              },
            ]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Platform"
                  variant="standard"
                  fullWidth
                />
              );
            }}
            onChange={(event, newValue) => {
              setPlatformId(newValue?.value);
            }}
          />
          <TextField
            id="quantity"
            label="Quantity"
            type="number"
            variant="standard"
            fullWidth
            value={quantity}
            onChange={(newValue) => setQuantity(+newValue.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            label="Cancel"
          />
          <Button
            disabled={
              isLoading ||
              !selectedCustomer?.id ||
              !selectedWallet ||
              !quantity ||
              !platformId
            }
            onClick={() => {
              mutate({
                customer_id: selectedCustomer?.id,
                wallet_id: +selectedWallet?.id,
                quantity,
                platform_id: +platformId,
              });
            }}
            label="Create"
          >
            <>
              {isLoading && (
                <CircularProgress
                  size={14}
                  sx={{
                    color: "#1976d2",
                  }}
                />
              )}
            </>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
