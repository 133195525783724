import {
  Datagrid,
  DateField,
  List,
  Pagination,
  ReferenceField,
  TextField,
  BooleanField,
  UrlField,
  Show,
  SimpleShowLayout,
  FilterForm,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  ReferenceManyField,
} from "react-admin";

import { Box, Stack } from "@mui/material";

const filters = [
  <ReferenceInput
    source="customer_id"
    label="Customer Name"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <SelectInput
    source="is_verified"
    choices={[
      { id: true, name: "Verified" },
      { id: false, name: "Not Verified" },
    ]}
  />,
];

const KycFormsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export function KycForms() {
  return (
    <List
      actions={<KycFormsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="customers" link="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="email" />
        <TextField source="reference_key" />
        <TextField source="provider_status" />
        <BooleanField source="is_verified" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </List>
  );
}

export function KycForm() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="customers" link="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="email" />
        <TextField source="reference_key" />
        <TextField source="provider_status" />
        <BooleanField source="is_verified" />
        <UrlField source="verification_url" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
}

export function KycFormsTab({
  reference,
  target,
  addLabel = false,
  pagination,
}) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
      pagination={pagination}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="customers" link="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="email" />
        <TextField source="reference_key" />
        <TextField source="provider_status" />
        <BooleanField source="is_verified" />
        <UrlField source="verification_url" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </ReferenceManyField>
  );
}
