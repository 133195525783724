import React from "react";
import { useMutation } from "react-query";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

export function SendToPartner({ record }) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(record?.comment || "");
  const [partnerId, setPartnerId] = React.useState(record?.customer_id || null);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClose = () => setOpen(false);

  const { mutate, isLoading } = useMutation(
    ({ comment }) =>
      dataProvider.approveLeadByIdWithPartner(record.id, {
        comment,
        customer_id: partnerId,
      }),
    {
      onSuccess: () => {
        refresh();
        notify("Sent Lead To Partner", { type: "success" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  function handleApproveBtn() {
    if (!record.email_verified_at) {
      notify("E-Mail Verification Required", { type: "warning" });

      return;
    }

    setOpen(true);
  }

  return (
    <>
      <Button
        disabled={["approved"].includes(record?.status) || isLoading}
        color="primary"
        label="Send to Partner"
        startIcon={<CheckIcon />}
        onClick={() => handleApproveBtn()}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Send Lead To Partner</DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={false}>
            <ReferenceInput source="customer_id" reference="customers">
              <AutocompleteInput
                autoFocus
                key={record?.customer_id}
                margin="dense"
                variant="standard"
                optionText="title"
                label="Customer Name"
                fullWidth
                debounce={100}
                value={partnerId}
                onChange={(e) => {
                  setPartnerId(e);
                }}
              />
            </ReferenceInput>
            <TextField
              margin="dense"
              id="name"
              label="Comment"
              type="text"
              variant="standard"
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => {
              mutate({ comment });
              setOpen(false);
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
