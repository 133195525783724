import { NumberField, useRecordContext } from "react-admin";

export const PriceField = ({ source }) => {
  const record = useRecordContext();
  return record ? (
    <NumberField
      source={source}
      options={{
        style: "currency",
        currency: record["currency"] ? record["currency"] : "USD",
        locales: "tr-TR",
      }}
    />
  ) : null;
};
