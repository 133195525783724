import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";

import { Box, Stack } from "@mui/material";

const filters = [
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput
    source="ad_account_id"
    label="Ad Account"
    reference="ad-accounts"
    allowEmpty
  >
    <AutocompleteInput optionText="account_name" debounce={500} />
  </ReferenceInput>,
];

const AdditionalInformationsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export const AdditionalInformationList = () => {
  return (
    <List
      actions={<AdditionalInformationsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <ReferenceField
          source="ad_account_id"
          reference="ad-accounts"
          link="show"
        >
          <TextField source="account_name" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="phone_country_code" />
        <TextField source="phone_number" />
        <TextField source="company_name" />
        <TextField source="platform_url" />
        <TextField source="platform_email" />
        <TextField source="platform_currency" />
        <TextField source="platform_timezone" />
        <TextField source="platform_country_code" />
        <TextField source="expected_spend_amount" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

export const AdditionalInformationShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField
          source="ad_account_id"
          reference="ad-accounts"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="phone_country_code" />
        <TextField source="phone_number" />
        <TextField source="company_name" />
        <TextField source="platform_url" />
        <TextField source="platform_email" />
        <TextField source="platform_currency" />
        <TextField source="platform_timezone" />
        <TextField source="platform_country_code" />
        <TextField source="expected_spend_amount" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
};
