// in src/App.js
import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "./resources/customers";
import {
  AdAccountList,
  AdAccountShow,
  PostEdit,
} from "./resources/adaccounts/adaccounts";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FeedIcon from "@mui/icons-material/Feed";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HubIcon from "@mui/icons-material/Hub";
import RequestPageIcon from "@mui/icons-material/RequestPage";
// import DataObjectIcon from "@mui/icons-material/DataObject";
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import rockadsDataProvider from "./rockadsDataProvider";
import authProvider from "./authProvider";
import { UserList, UserShow } from "./resources/users";
// import { InvoiceList, InvoiceShow } from "./resources/invoices";
import { PaymentList, PaymentShow } from "./resources/payments";
// import { LandingSessionList, LandingSessionShow } from "./resources/landingSessions";
import { Login } from "./resources/login";
import { BankTransferList, BankTransferShow } from "./resources/bankTransfer";
// import { ActivitySessionList, ActivitySessionShow } from "./resources/activity";
import { DemandsList, Demand } from "./resources/demands";
import { WalletsList, Wallet } from "./resources/wallets/wallets";
import {
  WalletTransactions,
  WalletTransaction,
} from "./resources/walletTransactions";
import { BankAccountsList, BankAccountShow } from "./resources/bankAccounts";
import {
  BillingAccountList,
  BillingAccountShow,
} from "./resources/billingAccounts";

import {
  AdditionalInformationList,
  AdditionalInformationShow,
} from "./resources/additionalInformations";
import {
  Invoices,
  Invoice,
  InvoiceCreate,
} from "./resources/invoices/Invoices";
import { Receipts, Receipt } from "./resources/receipts/Receipts";
import { KycForms, KycForm } from "./resources/kyc-forms/KycForms";
import { ActivityLogs } from "./resources/activityLogs";
import { Leads, Lead } from "./resources/leads";

const App = () => (
  <Admin
    dataProvider={rockadsDataProvider}
    authProvider={authProvider}
    loginPage={Login}
  >
    <Resource
      name="customers"
      list={CustomerList}
      show={CustomerShow}
      icon={WorkIcon}
      edit={CustomerEdit}
    />
    <Resource name="users" list={UserList} show={UserShow} icon={PeopleIcon} />
    <Resource
      name="invoices"
      list={Invoices}
      show={Invoice}
      icon={ReceiptLongIcon}
      create={InvoiceCreate}
    />
    <Resource
      name="receipts"
      list={Receipts}
      show={Receipt}
      icon={ReceiptIcon}
    />
    <Resource
      name="payments"
      list={PaymentList}
      show={PaymentShow}
      icon={PaymentIcon}
    />
    <Resource
      name="ad-accounts"
      list={AdAccountList}
      show={AdAccountShow}
      edit={PostEdit}
    />
    <Resource
      name="bank-transfers"
      list={BankTransferList}
      show={BankTransferShow}
      icon={WorkIcon}
    />
    <Resource
      name="demands"
      list={DemandsList}
      show={Demand}
      icon={HourglassBottomIcon}
    />
    <Resource
      name="wallets"
      list={WalletsList}
      show={Wallet}
      icon={AccountBalanceWalletIcon}
    />
    <Resource
      name="bank-accounts"
      list={BankAccountsList}
      show={BankAccountShow}
      icon={AccountBalanceIcon}
    />
    <Resource
      name="billing-accounts"
      list={BillingAccountList}
      show={BillingAccountShow}
      icon={FeedIcon}
    />
    <Resource
      name="additional-informations"
      list={AdditionalInformationList}
      show={AdditionalInformationShow}
      icon={PermIdentityIcon}
    />
    <Resource
      name="kyc-forms"
      list={KycForms}
      show={KycForm}
      icon={FingerprintIcon}
    />
    <Resource
      name="payments/wallet-transactions"
      options={{ label: "Wallet Transactions" }}
      list={WalletTransactions}
      show={WalletTransaction}
      icon={PaymentIcon}
    />
    <Resource name="activity-logs" list={ActivityLogs} icon={HubIcon} />
    <Resource name="leads" list={Leads} show={Lead} icon={RequestPageIcon} />
  </Admin>
);

export default App;
