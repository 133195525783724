import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useRedirect } from "react-admin";
import GoogleIcon from "@mui/icons-material/Google";
import { Button, Stack } from "@mui/material";
import logo from "../logo.svg";

var config = {
  apiKey: "AIzaSyDvmXCdajYGZrObSsdstEVCTWIWkNLtBj0",
  authDomain: "rockads.firebaseapp.com",
};
initializeApp(config);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  hd: "teknasyon.com",
});
const auth = getAuth();

const SignIn = (redirect) => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      localStorage.setItem("token", user.accessToken);
      redirect("/customers");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const Login = () => {
  const redirect = useRedirect();
  if (localStorage.getItem("token")) redirect("/customers");

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={12}
      mt={24}
    >
      <img src={logo} alt="RockAds" width={480} />
      <Button
        onClick={() => SignIn(redirect)}
        variant="outlined"
        startIcon={<GoogleIcon />}
      >
        Login With Google
      </Button>
    </Stack>
  );
};
