import {
  AutocompleteInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FileField,
  FileInput,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
  useNotify,
  useRedirect,
} from "react-admin";

import { Box, Stack } from "@mui/material";
import html2pdf from "html2pdf.js";
import { useRecordContext } from "react-admin";

const filters = [
  <ReferenceInput
    source="id"
    label="Invoice Id"
    reference="invoices"
    allowEmpty
  >
    <AutocompleteInput optionText="id" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput
    source="customer_id"
    label="Customer Name"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput
    source="ad_account_id"
    label="Ad Account Id"
    reference="ad-accounts"
    allowEmpty
  >
    <AutocompleteInput optionText="id" debounce={500} />
  </ReferenceInput>,
  <SelectInput
    source="currency_code"
    choices={[
      { id: "eur", name: "EUR" },
      { id: "usd", name: "USD" },
      { id: "try", name: "TRY" },
    ]}
  />,
];

const InvoicesActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
          <CreateButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export function Invoices() {
  return (
    <List
      actions={<InvoicesActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="invoice_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="ad_account_id"
          reference="ad-accounts"
          link="show"
          label="Ad Account Id"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="currency_code" />
        <TextField source="is_emailed" />
        <DateField source="invoice_date" locales="tr-TR" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
}

const InvoiceDownloader = () => {
  const record = useRecordContext();
  const isRockadsInvoice = record?.invoice_url?.includes("files.rockads.com");

  if (!isRockadsInvoice) {
    return null;
  }

  const downloadPDF = async () => {
    try {
      // Get the HTML page with fetch
      const response = await fetch(record.invoice_url);
      const htmlContent = await response.text();

      // Wrap HTML content with padding
      const container = document.createElement("div");
      container.innerHTML = htmlContent;

      // Create a wrapper div to add padding
      const wrapper = document.createElement("div");
      wrapper.style.padding = "40px";
      wrapper.appendChild(container);

      // Download as PDF
      html2pdf().from(wrapper).save("invoice.pdf");
    } catch (error) {
      console.error("PDF indirme sırasında hata:", error);
    }
  };

  return <button onClick={downloadPDF}>Download invoice as PDF</button>;
};

export function Invoice() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="invoice_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="ad_account_id"
          reference="ad-accounts"
          link="show"
          label="Ad Account Id"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="sub_total_amount" />
        <TextField source="total_tax_amount" />
        <TextField source="tax_rate" />
        <TextField source="total_amount" />
        <TextField source="currency_code" />
        <UrlField source="invoice_url" />
        <InvoiceDownloader />
        <TextField source="is_emailed" />
        <DateField source="invoice_date" locales="tr-TR" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
}

export function InvoiceCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  const onError = (error) => {
    notify(`Error: ${error.error}`, "error");
  };

  const onSuccess = () => {
    notify("Invoice created successfully", "info");
    redirect("/invoices");
  };

  return (
    <Create
      title="New Invoice"
      mutationOptions={{
        onError,
        onSuccess,
      }}
    >
      <SimpleForm>
        <Stack>
          <TextField source="title" />
          <TextField source="invoice_number" />
          <ReferenceInput
            source="customer_id"
            reference="customers"
            label="Customer Name"
          >
            <AutocompleteInput optionText="title" />
          </ReferenceInput>
          <TextInput source="invoice_number" />
          <TextInput source="title" />
          <DateInput source="invoice_date" />
          <TextInput source="sub_total_amount" />
          <TextInput source="total_tax_amount" />
          <TextInput source="total_amount" />
          <TextInput source="tax_rate" />
          <SelectInput
            source="currency_code"
            choices={[
              { id: "EUR", name: "EUR" },
              { id: "USD", name: "USD" },
              { id: "TRY", name: "TRY" },
            ]}
          />
          <FileInput source="file" label="File" accept="application/pdf">
            <FileField source="src" title="title" />
          </FileInput>
        </Stack>
      </SimpleForm>
    </Create>
  );
}

export function InvoiceTab({ reference, target }) {
  return (
    <ReferenceManyField reference={reference} target={target}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="invoice_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="ad_account_id"
          reference="ad-accounts"
          link="show"
          label="Ad Account Id"
        >
          <TextField source="id" />
        </ReferenceField>

        <TextField source="currency_code" />
        <TextField source="is_emailed" />
        <DateField source="invoice_date" showTime locales="tr-TR" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
