import React from "react";
import {
  Datagrid,
  List,
  Pagination,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  SelectInput,
  FilterForm,
  FilterButton,
  ExportButton,
  DateField,
  TextInput,
  UrlField,
  ReferenceField,
  DateInput,
} from "react-admin";
import { Box, Stack } from "@mui/material";

import { Approve } from "./Approve";
import { Reject } from "./Reject";
import { SendToPartner } from "./SendToPartner";

const filters = [
  <SelectInput
    source="status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "approved", name: "Approved" },
      { id: "rejected", name: "Rejected" },
      { id: "partner", name: "Partner" },
    ]}
  />,
  <TextInput label="Email" source="email" />,
  <TextInput label="Country (Only country code)" source="country" />,
  <SelectInput
    source="approximate_monthly_spend"
    label="Approximate Monthly Spend"
    choices={[
      { id: "< $25K", name: "< $25K" },
      { id: "< $100K", name: "< $100K" },
      { id: "$25K - $50K", name: "$25K - $50K" },
      { id: "$50K - $100K", name: "$50K - $100K" },
      { id: "$100K - $250K", name: "$100K - $250K" },
      { id: "$250K +", name: "$250K +" },
      { id: "$100K - $500K", name: "$100K - $500K" },
      { id: "$500K - $1M", name: "$500K - $1M" },
      { id: "$1M - $5M", name: "$1M - $5M" },
      { id: "$5M +", name: "$5M +" },
    ]}
  />,
  <SelectInput
    source="customer_type"
    choices={[
      { id: "agency", name: "Agency" },
      { id: "partner", name: "Partner" },
      { id: "advertiser", name: "Advertiser" },
    ]}
  />,
  <DateInput source="created_at_gte" label="Created At Gte (Start Date)" />,
  <DateInput source="created_at_lte" label="Created At Lte (End Date)" />,
];

const LeadsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export function Leads() {
  return (
    <List
      actions={<LeadsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="customer_type" />
        <TextField source="approximate_monthly_spend" />
        <TextField source="business_name" />
        <TextField source="ad_platforms" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <TextField source="status" />
        <TextField source="client_business_industries" />
        <TextField source="client_count" />
        <TextField source="country" />
        <TextField source="country_code" />
        <TextField source="phone_country_code" />
        <TextField source="phone_number" />
        <TextField source="email" />
        <TextField source="language" />
        <TextField source="services" />
        <TextField source="website" />
      </Datagrid>
    </List>
  );
}

export function Lead() {
  return (
    <Show actions={<LeadActions />}>
      <SimpleShowLayout>
        <TextField source="approximate_monthly_spend" />
        <TextField source="business_name" />
        <TextField source="ad_platforms" />
        <TextField source="client_business_industries" />
        <TextField source="client_count" />
        <TextField source="client_regions" />
        <TextField source="country" />
        <TextField source="country_code" />
        <TextField source="phone_country_code" />
        <TextField source="phone_number" />
        <TextField source="customer_type" />
        <TextField source="email" />
        <TextField source="ip_address" />
        <TextField source="language" />
        <TextField source="services" />
        <TextField source="status" />
        <ReferenceField source="customer_id" reference="customers" link="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="comment" />
        <TextField source="utm_sources" />
        <UrlField source="website" />
        <DateField source="created_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
}

const LeadActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <SendToPartner record={record} />
      <Approve record={record} />
      <Reject record={record} />
    </TopToolbar>
  );
};
