import {
  useNotify,
  useRefresh,
  useDataProvider,
  Button,
  useRecordContext,
  Confirm,
} from "react-admin";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export const ApproveButton = ({ bankTransferId, limit, wallet }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [bankAccountId, setBankAccountId] = useState("");
  const approve = useMutation(
    ({ bankTransferId, amount, bankAccountId }) =>
      dataProvider.approveBankTransfer(bankTransferId, amount, bankAccountId),
    {
      onSuccess: () => {
        refresh();
        notify("Bank transfer approved", { type: "success" });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event:
            wallet.balance === 0
              ? "bank_transfer_approved_first"
              : "bank_transfer_approved",
          payment_method: "Havale", // Havale, Kredi Kartı, Paypal
          currency: wallet.currency_code, // USD, EUR, TRY
          credit_balance: wallet.balance, // Mevcut cüzdan bakiyesi */
          amount: amount,
        });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  const handleConfirm = (amount, bankAccountId) => {
    setOpen(true);
    setAmount(amount);
    setBankAccountId(bankAccountId);
  };

  const approveAmount = () => {
    approve.mutate({ bankTransferId, amount, bankAccountId });
    setOpen(false);
  };

  return (
    <>
      <FormDialog handleConfirm={handleConfirm} />
      <Confirm
        title="Are you sure?"
        content={
          parseInt(amount) >= parseInt(limit)
            ? "The balance loading limit for the relevant user is exceeded, if you still want to continue, please confirm."
            : "This bank transfer will be approved."
        }
        isOpen={open}
        onConfirm={approveAmount}
        onClose={() => setOpen(false)}
        isLoading={approve.isLoading}
      />
    </>
  );
};

export default function FormDialog({ handleConfirm }) {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  let amount = record.amount;
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState(amount);
  const [bankAccountId, setBankAccountId] = useState("none");
  const [bankAccounts, setBankAccounts] = useState([]);

  const fetchBankAccounts = async () => {
    const { data } = await dataProvider.getList("bank-accounts", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {
        payment_method: [
          "bank_transfer",
          "wise",
          "stripe",
          "paypal",
          "crypto",
          "mercury",
          "payoneer",
        ],
        currency_code: record.currency_code,
      },
    });

    setBankAccounts(data);
  };

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        color="primary"
        label="Approve"
        startIcon={<CheckIcon />}
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve Bank Transfer</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Amount"
              type="text"
              variant="standard"
              fullWidth
              value={newValue}
              onChange={(newValue) => setNewValue(newValue.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel
              variant="standard"
              margin="dense"
              id="bank-account-label"
            >
              Bank Account
            </InputLabel>
            <Select
              variant="standard"
              margin="dense"
              labelId="bank-account-label"
              id="bank-account"
              value={bankAccountId}
              label="Bank Account"
              onChange={(e) => setBankAccountId(e.target.value)}
            >
              <MenuItem value="none" key="none">
                <em>Select a bank account</em>
              </MenuItem>
              {bankAccounts.map((ba) => {
                return (
                  <MenuItem key={ba.id} value={ba.id}>
                    {ba.payment_method === "crypto"
                      ? ba.name +
                        " - " +
                        ba.currency_code +
                        " - " +
                        ba.meta?.protocol
                      : ba.name + " - " + ba.currency_code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={handleClose} />
          <Button
            label="Approve"
            disabled={newValue === 0 || bankAccountId === "none"}
            onClick={() => {
              handleConfirm(newValue, bankAccountId);
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
