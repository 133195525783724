export const PLATFORM = {
  META: 1,
  TIKTOK: 3,
  GOOGLE: 4,
}

export const PLATFORM_CURRENCIES = {
  [PLATFORM.META]: ['USD', 'EUR', 'TRY'],
  [PLATFORM.TIKTOK]: ['USD'],
  [PLATFORM.GOOGLE]: ['USD', 'EUR'],
}

export const AD_ACCOUNT_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}