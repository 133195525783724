import {
  Datagrid,
  DateField,
  List,
  Pagination,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const BankAccountsList = () => {
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="currency_code" />
        <TextField source="company_title" />
        <TextField source="bank_branch" />
        <TextField source="payment_method" />
        <TextField source="account_number" />
        <TextField source="swift_code" />
        <TextField source="is_active" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

export const BankAccountShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="currency_code" />
        <TextField source="company_title" />
        <TextField source="bank_branch" />
        <TextField source="payment_method" />
        <TextField source="account_number" />
        <TextField source="swift_code" />
        <TextField source="is_active" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
};
