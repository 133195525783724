import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FilterForm,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  UrlField,
} from "react-admin";

import { Box, Stack } from "@mui/material";

const filters = [
  <ReferenceInput
    source="id"
    label="Receipt Id"
    reference="receipts"
    allowEmpty
  >
    <AutocompleteInput optionText="id" debounce={500} />
  </ReferenceInput>,
  <ReferenceInput
    source="customer_id"
    label="Customer Name"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <SelectInput
    source="currency_code"
    choices={[
      { id: "eur", name: "EUR" },
      { id: "usd", name: "USD" },
      { id: "try", name: "TRY" },
    ]}
  />,
];

const ReceiptsActions = () => {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Box>
        <FilterForm filters={filters} />
      </Box>
      <Box>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton />
        </TopToolbar>
      </Box>
    </Stack>
  );
};

export function Receipts() {
  return (
    <List
      actions={<ReceiptsActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" size="medium">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="receipt_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="wallet_id"
          reference="wallets"
          link="show"
          label="Wallet ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="currency_code" />
        <DateField source="receipt_date" locales="tr-TR" />
        <DateField source="send_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
}

export function Receipt() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="receipt_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="wallet_id"
          reference="wallets"
          link="show"
          label="Wallet ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="sub_total_amount" />
        <TextField source="total_amount" />
        <TextField source="card_fee" />
        <TextField source="card_fee_rate" />
        <TextField source="currency_code" />
        <UrlField source="receipt_url" />
        <DateField source="receipt_date" locales="tr-TR" />
        <DateField source="send_at" showTime locales="tr-TR" />
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </SimpleShowLayout>
    </Show>
  );
}

export function ReceiptTab({ reference, target }) {
  return (
    <ReferenceManyField reference={reference} target={target}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="receipt_number" />
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="wallet_id"
          reference="wallets"
          link="show"
          label="Wallet ID"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="currency_code" />
        <DateField source="receipt_date" showTime locales="tr-TR" />
        <DateField source="send_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
