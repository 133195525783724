import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Button,
  Show,
  SimpleShowLayout,
  TextInput,
  SelectInput,
  ReferenceField,
  useRecordContext,
  TopToolbar,
  BulkExportButton,
  Edit,
  SimpleForm,
  EditButton,
  useDataProvider,
  SaveButton,
  Toolbar,
  useNotify,
  ListButton,
  required,
  WrapperField,
  NumberField,
  Pagination,
  FunctionField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  FilterForm,
  FilterButton,
  ExportButton,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
} from "react-admin";
import { ApproveButton } from "./approve";
import { RejectButton } from "./reject";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useMutation } from "react-query";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import { CreateAdAccount } from "./createAdAccount";

const filters = [
  <TextInput label="ID" source="id" />,
  <SelectInput
    source="status"
    choices={[
      { id: "draft", name: "Draft" },
      { id: "pending", name: "Pending" },
      { id: "approved", name: "Approved" },
      { id: "banned", name: "Banned" },
      { id: "deleted", name: "Deleted" },
    ]}
  />,
  <ReferenceInput
    source="customer_id"
    label="Customer"
    reference="customers"
    allowEmpty
  >
    <AutocompleteInput optionText="title" debounce={500} />
  </ReferenceInput>,
  <TextInput label="Account" source="account_id" />,
  <TextInput label="Account Name" source="account_name" />,
  <DateInput source="created_at_gte" label="Created At Gte (Start Date)" />,
  <DateInput source="created_at_lte" label="Created At Lte (End Date)" />,
  <SelectInput
    source="platform_id"
    choices={[
      { id: 1, name: "Meta" },
      { id: 3, name: "Tiktok" },
      { id: 4, name: "Google" },
    ]}
  />,
];

const BulkActionButtons = () => (
  <>
    <BulkExportButton />
  </>
);

const ListActions = () => {
  return (
    <Stack
      direction="row"
      width={"100%"}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <div>
        <FilterForm filters={filters} />
      </div>
      <div>
        <TopToolbar>
          <FilterButton filters={filters} />
          <ExportButton maxResults={50000} />
          <CreateAdAccount />
        </TopToolbar>
      </div>
    </Stack>
  );
};

export const AdAccountList = () => {
  const handleAvailableField = (record) => {
    if (record.has_additional_data) {
      return (
        <div
          style={{
            backgroundColor: "green",
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            margin: "0 auto",
          }}
        ></div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: "red",
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          margin: "0 auto",
        }}
      ></div>
    );
  };
  return (
    <List
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid
        size="medium"
        rowClick="show"
        bulkActionButtons={<BulkActionButtons />}
      >
        <ReferenceField
          source="id"
          reference="ad-accounts"
          link="show"
          label="Id"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="customer_id"
          reference="customers"
          link="show"
          label="Customer Name"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="platform_settings_id"
          reference="platform-settings"
          label="BM Name"
        >
          <TextField source="bm_name" />
        </ReferenceField>
        <TextField source="platform_id"></TextField>
        <TextField source="account_id"></TextField>
        <TextField source="account_name"></TextField>
        <TextField source="status"></TextField>
        <TextField source="currency_code" label="Currency"></TextField>
        <TextField source="balance"></TextField>
        <TextField source="timezone"></TextField>
        <TextField
          sortable={false}
          source="additional_information.platform_email"
          label="Email"
        ></TextField>
        <FunctionField
          label="URL"
          render={(record) =>
            CustomUrlField(record.additional_information.platform_url)
          }
        />
        <FunctionField
          label="Available"
          render={(record) => handleAvailableField(record)}
        ></FunctionField>
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </List>
  );
};

const CustomUrlField = (url) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      {url}
    </a>
  );
};

const CreateWithDrawButton = ({ id, balance }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dataProvider
      .getList("demands", {
        pagination: { page: 1, perPage: 100000 },
        sort: { field: "id", order: "ASC" },
        filter: {
          object_id: id,
          demand_name: "ad-account-withdraw",
          status: "in_progress",
        },
      })
      .then((res) => {
        setDisabled(res.data.length > 0 || balance === 0);
      });
  }, []);

  const createWithdraw = async () => {
    try {
      await dataProvider.createWithdrawDemandByBannedAdAccount(id);

      notify("Withdraw request created", { type: "success" });
      setLoading(true);
    } catch (error) {
      notify(error.error, { type: "error" });
    }
  };

  return (
    <Button
      onClick={createWithdraw}
      label="Create Withdraw"
      disabled={disabled}
      loading={loading}
    />
  );
};

const SyncBalanceButton = ({ id = "" }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const syncBalance = async () => {
    try {
      await dataProvider.syncBalance(id);

      notify("Sync successful", { type: "success" });
    } catch (error) {
      notify(error.error, { type: "error" });
    }
  };

  return <Button onClick={syncBalance} label="Sync Balance" />;
};

const ShowActions = () => {
  const record = useRecordContext();

  if (record && record.status === "approved") {
    return (
      <TopToolbar>
        <SyncBalanceButton id={record?.id} />
        <EditButton />
      </TopToolbar>
    );
  }

  if (record && record.status === "pending" && record.subscription_id !== "0") {
    return (
      <TopToolbar>
        <ApproveButton
          status={record.status}
          adAccountId={record.id}
          platformId={record.platform_id}
          platformCurrency={record.platform_currency}
          platformTimezone={record.platform_timezone}
          commissionCategory={record.commission_category_id}
          platformCountryCode={record.platform_country_code}
        />
        <RejectButton adAccountId={record.id} />
      </TopToolbar>
    );
  }

  if (record && record.status === "banned") {
    return (
      <TopToolbar>
        <SyncBalanceButton id={record?.id} />
        <CreateWithDrawButton id={record?.id} balance={+record.balance} />
      </TopToolbar>
    );
  }

  return <TopToolbar />;
};

export const AdditionalInfo = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="ad_account_id"></TextField>
        <TextField source="phone_country_code"></TextField>
        <TextField source="phone_number"></TextField>
        <TextField source="platform_currency"></TextField>
        <TextField source="platform_email"></TextField>
        <TextField source="platform_id"></TextField>
        <TextField source="platform_timezone"></TextField>
        <TextField source="platform_url"></TextField>
        <TextField source="platform_country_code"></TextField>
      </SimpleShowLayout>
    </Show>
  );
};

export const AdAccountShow = () => {
  const getBusinessManagerLink = (record) => {
    if (!record.bm_id || !record.account_id) {
      return "";
    }

    return `https://business.facebook.com/settings/ad-accounts/${record.account_id}?business_id=${record.bm_id}`;
  };

  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="Details">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField source="id"></TextField>
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  link="show"
                  label="Customer Name"
                >
                  <TextField source="title" />
                </ReferenceField>
                <ReferenceField
                  source="customer_id"
                  reference="customers"
                  link="show"
                  label="Customer ID"
                >
                  <TextField source="id" />
                </ReferenceField>
                <ReferenceField
                  source="platform_settings_id"
                  reference="platform-settings"
                  label="BM Name"
                  emptyText="-"
                >
                  <TextField source="bm_name" />
                </ReferenceField>
                <ReferenceField
                  source="wallet_id"
                  reference="wallets"
                  link="show"
                  label="Wallet ID"
                >
                  <TextField source="id" />
                </ReferenceField>
                <TextField source="platform_id"></TextField>
                <TextField source="account_id"></TextField>
                <TextField source="account_name"></TextField>
                <TextField source="status"></TextField>
                <TextField source="currency_code"></TextField>
                <TextField
                  source="bm_id"
                  label="Business Manager Id"
                ></TextField>
                <DateField source="created_at" showTime locales="tr-TR" />
                <DateField source="updated_at" showTime locales="tr-TR" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <WrapperField label="Current Balance">
                  <NumberField source="balance" />{" "}
                  <TextField source="currency" />
                </WrapperField>
                <TextField source="work_type"></TextField>
                <WrapperField label="Auto Renew">
                  <TextField source="is_auto_renew_prepayment" />{" "}
                  <TextField source="auto_renew_type" />
                </WrapperField>
                <TextField
                  source="commission_rate.commission_category_id"
                  label="Commission Category Id"
                ></TextField>
                <TextField
                  source="commission_rate.commission_category_name"
                  label="Commission Category Name"
                ></TextField>
                <TextField
                  source="commission_rate.commission_rate"
                  label="Commission Rate"
                ></TextField>
                <FunctionField
                  label="Business Manager Link"
                  render={(record) => {
                    if (record.bm_id && record.account_id) {
                      return CustomUrlField(getBusinessManagerLink(record));
                    } else {
                      return "-";
                    }
                  }}
                />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="Additional Information" path="additional-informations">
          <ReferenceManyField
            target="ad_account_id"
            reference="additional-informations"
            addLabel={false}
          >
            <Datagrid rowClick="show" size="medium">
              <TextField source="ad_account_id"></TextField>
              <TextField source="company_name"></TextField>
              <TextField source="expected_spend_amount"></TextField>
              <TextField source="id"></TextField>
              <TextField source="phone_country_code"></TextField>
              <TextField source="phone_number"></TextField>
              <TextField source="platform_currency"></TextField>
              <TextField source="platform_email"></TextField>
              <TextField source="platform_id"></TextField>
              <TextField source="platform_timezone"></TextField>
              <FunctionField
                label="URL"
                render={(record) => CustomUrlField(record.platform_url)}
              />
              <TextField source="platform_country_code"></TextField>
              <DateField source="created_at" showTime locales="tr-TR" />
              <DateField source="updated_at" showTime locales="tr-TR" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PostEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton style={{ marginRight: "16px" }} />
      <ListButton
        variant="contained"
        size="medium"
        label="Back"
        icon={<ChevronLeft />}
      />
    </Toolbar>
  );
};

export const PostEdit = () => {
  const [categories, setCategories] = useState([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const mutation = useMutation(({ id, commission_category_id }) => {
    return dataProvider.update("ad-accounts", {
      id,
      data: { commission_category_id },
    });
  });

  const mutationOnSuccess = () => {
    notify("Ad account updated", { type: "success" });
  };
  const mutationOnError = (err) => {
    notify(err.error, { type: "warning" });
  };

  useEffect(() => {
    fetchData().then((res) => {
      setCategories(res.data);
    });
  }, []);

  const fetchData = async () => {
    const CommissionCategories = await dataProvider.getCommissionCategories();
    return CommissionCategories;
  };

  const transform = (data) => ({
    commission_category_id: +data.commission_category_id,
  });

  return (
    categories && (
      <Edit
        transform={transform}
        mutationMode="optimistic"
        mutationOptions={{
          mutationFn: mutation,
          onSuccess: mutationOnSuccess,
          onError: mutationOnError,
        }}
      >
        <>
          <SimpleShowLayout>
            <TextField source="id"></TextField>
            <ReferenceField
              source="customer_id"
              reference="customers"
              link="show"
              label="Customer Name"
            >
              <TextField source="title" />
            </ReferenceField>
            <ReferenceField
              source="customer_id"
              reference="customers"
              link="show"
              label="Customer ID"
            >
              <TextField source="id" />
            </ReferenceField>
            <TextField source="platform_id"></TextField>
            <TextField source="account_id"></TextField>
            <TextField source="account_name"></TextField>
            <TextField source="status"></TextField>
            <TextField source="currency_code"></TextField>
            <TextField source="bm_id" label="Business Manager Id"></TextField>
            <ReferenceField
              source="commission_category_id"
              reference="commission-categories"
              label="Commission Category Id"
              link={false}
            >
              <TextField source="id" />
            </ReferenceField>
            <ReferenceField
              source="commission_category_id"
              reference="commission-categories"
              label="Commission Category Name"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" showTime locales="tr-TR" />
            <DateField source="updated_at" showTime locales="tr-TR" />
          </SimpleShowLayout>
          <SimpleForm toolbar={<PostEditToolbar />}>
            <span className="ra-field ra-field-currency RaSimpleShowLayout-row">
              <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label">
                <span>Commission Category</span>
              </p>
            </span>
            <SelectInput
              source="commission_category_id"
              choices={categories}
              emptyText="Select a commission category"
              validate={required()}
            />
          </SimpleForm>
        </>
      </Edit>
    )
  );
};

export function AdAccountTab({ reference, target, addLabel }) {
  return (
    <ReferenceManyField
      reference={reference}
      target={target}
      addLabel={addLabel}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} />}
    >
      <Datagrid
        rowClick="show"
        size="medium"
        bulkActionButtons={<BulkActionButtons />}
      >
        <ReferenceField source="id" reference="ad-accounts" link="show">
          <TextField source="id" />
        </ReferenceField>
        <TextField source="platform_id"></TextField>
        <TextField source="account_id"></TextField>
        <TextField source="account_name"></TextField>
        <TextField source="status"></TextField>
        <TextField source="currency_code" label="Currency"></TextField>
        <TextField source="balance"></TextField>
        <DateField source="created_at" showTime locales="tr-TR" />
        <DateField source="updated_at" showTime locales="tr-TR" />
      </Datagrid>
    </ReferenceManyField>
  );
}
