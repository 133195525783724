import React from "react";
import { useMutation } from "react-query";
import { Button, useDataProvider, useNotify, useRefresh } from "react-admin";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

export function Reject({ record }) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClose = () => setOpen(false);

  const { mutate, isLoading, isSuccess } = useMutation(
    ({ comment }) => dataProvider.rejectLeadById(record.id, { comment }),
    {
      onSuccess: () => {
        refresh();
        notify("Rejected Lead", { type: "info" });
      },
      onError: (err) => {
        notify(err.error, { type: "warning" });
      },
    }
  );

  return (
    <>
      <Button
        disabled={
          ["approved", "rejected"].includes(record?.status) ||
          isLoading ||
          isSuccess
        }
        color="primary"
        label="reject"
        startIcon={<ClearIcon />}
        onClick={() => setOpen(true)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Reject Lead</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Comment"
            type="text"
            variant="standard"
            fullWidth
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!comment || isLoading || isSuccess}
            color="primary"
            onClick={() => {
              mutate({ comment });
              setOpen(false);
            }}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
