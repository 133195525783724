import { useState } from "react";
import { Button, useDataProvider, useNotify, useRefresh } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import MoneyOffCsredIcon from "@mui/icons-material/MoneyOffCsred";
import { useMutation } from "react-query";

export function Refund({
  confirm,
  loading,
  open,
  setOpen,
  amount,
  setAmount,
  comment,
  setComment,
}) {
  return (
    <>
      <Button
        color="primary"
        label="Refund"
        onClick={() => setOpen(true)}
        startIcon={<MoneyOffCsredIcon />}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Refund</DialogTitle>
        <DialogContent>
          <TextField
            id="amount"
            label="Amount"
            type="number"
            variant="standard"
            fullWidth
            value={amount}
            required
            onChange={(newValue) => setAmount(+newValue.target.value)}
          />
          <TextField
            id="comment"
            label="Comment"
            type="text"
            variant="standard"
            fullWidth
            value={comment}
            multiline
            required
            onChange={(newValue) => setComment(newValue.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={() => setOpen(false)} />
          <Button
            label="Refund"
            disabled={!amount || !comment}
            loading={loading}
            onClick={() =>
              confirm({
                amount,
                comment,
              })
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export function RefundButton({ id: wallet_id }) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [comment, setComment] = useState("");

  const payload = {
    amount,
    comment,
    wallet_id,
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const refund = useMutation(() => dataProvider.walletRefund(payload), {
    onSuccess: () => {
      notify("Refund success", { type: "success" });
      setOpen(false);
      refresh();
      resetForm();
    },
    onError: (err) => {
      notify(err.error, { type: "warning" });
    },
  });

  const resetForm = () => {
    setAmount("");
    setComment("");
  };

  return (
    <Refund
      confirm={refund.mutateAsync}
      loading={refund.isLoading}
      open={open}
      setOpen={setOpen}
      amount={amount}
      setAmount={setAmount}
      comment={comment}
      setComment={setComment}
    />
  );
}
